.custom-list {
  list-style: none;
  margin-left: 10px;
}

.custom-list-item1 {
  position: relative;
  padding-left: 10px;
}

.custom-list-item1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 6px;
  height: 6px;
  background-color: #000;
  /* Màu sắc biểu tượng */
  border-radius: 50%;
}

.custom-list-item2 {
  position: relative;
  padding-left: 10px;
}

.custom-list-item2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 6px;
  height: 6px;
  border: 1px solid #000;
  /* Màu sắc biểu tượng */
  border-radius: 50%;
}