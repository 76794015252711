.sound-container {
  user-select: 'none';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: 60;
  width: 60;
  margin-bottom: 20;
  background-color: '#f4f5f7';
  color: '#172b4d';
  border-radius: '0.25rem';
  border: '1px solid #adb5bd';
}
