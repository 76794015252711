.card-stats {
  height: calc(100% - 30px); /* 30px là margin-bottom mặc định của Card trong antd. */
}

.card-stats > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-countup {
  font-size: 1.25rem;
  font-weight: bold;
  /* padding-inline: 1rem; */
}

.dashboard-child-card-title {
  font-size: 0.7rem;
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
}
