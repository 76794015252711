.steps-content {
    height: 550px;
    text-align: center;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 40px;
    float: right;
  }

  .steps-action.steps-action-center {
    margin-top: 40px;
    float: none;
    display: flex;
    justify-content: center;

  }
  

  

  .steps-action .ant-btn{
    font-size: 18px;
    font-weight: 700;
    height: auto;
    width: 150px;
    padding: 10px 30px;
    margin: 0 8px;
  }

  .steps-action .ant-btn.ant-btn-primary{
    background-color: #102F5F;
  }

  .tutorial-card .card-header{
    text-align: center;
    background-color: #102F5F;
  }

  .start-test-btn{
    font-size: 17px !important;
    height: auto;
    width: 150px;
    padding: 10px 30px;
    margin: 0 8px;
  }

   .test-info-content p{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
   }

   /* .test-info-content p strong{
    font-weight: 700;
   } */

   .test-info-content p span{
    color: #32325d !important;
   }

   .mask{
    opacity: 0.5 ;
   }

   .sound-wave{
    width: 400px;
   }
  