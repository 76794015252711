input.verify-otp-input {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 4px 11px;
  width: 100%;
}

input.verify-otp-input:hover {
  border: 1px solid #2790f2;
  transition: all 0.3s ease;
}
input.verify-otp-input:focus {
  border: 1px solid #2790f2;
  box-shadow: 0 0 0 2px rgba(0, 110, 230, 0.2);
  transition: all 0.3s ease;
}
