.answers-unscramble {
  display: flex;
  flex-direction: row;
}

.answerButton-unscramble {
  margin-right: 16px !important;
  margin-bottom: 16px !important;
}

/* sm */
@media screen and (max-width: 540px) {
  .answers-unscramble {
    display: flex;
    flex-direction: column;
  }
  .answerButton-unscramble {
    margin: 0px !important;
    margin-bottom: 16px !important;
  }
}
