
.point-item{
    animation: point 1.5s ease-in-out infinite;
}
@keyframes point {
    from {
        fill: #ff7979;
        stroke: white;
    }

    to {

    }
}