
.intro-modal{
 min-width: 330px;
 max-width: 950px;
 max-height: 1000px;
  
}
.intro-modal .intro-modal-content{
    position: relative;
    max-height: 1200px;
}
.intro-modal .intro-modal-content .modal-body{
    max-height: 1200px;
}
.intro-modal-title{
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
   
    text-align: center;
    /* font-size: 20px; */
    line-height: 1.6;
    color:white;
    font-weight: bold;

/* text-shadow: 0 1px #dcdde3, -1px 0 #dcdde3, -1px 2px #dcdde3, -2px 1px #dcdde3,0 1px #dcdde3, -1px 0 #dcdde3; */

   
}


.carousel-inner{
    height: 100%;
    overflow: hidden;
    
}
.intro-carousel {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 30px;
  height: 500px;
}
.intro-carousel .carousel-inner{
    width: 100%;
}
.carousel-item{
    height: 100%;
    
}



.carousel-inner img{
    /* object-fit: cover; */
    /* width: 100%; */
    height: 100%
    
}
