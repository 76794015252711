.slide-row:hover {
  background-color: lightblue;
}
.ant-card-bordered {
  border: none;
}
.ant-card-bordered .ant-card-body {
  padding: 0;
}
.swimlane_col_lesson_container {
  min-width: 350px;
  flex: 1 1;
  border: 1px solid wheat;
}
.lg-checked-background .ant-checkbox .ant-checkbox-inner{
  background-color: white;
  border:1px solid #636e72 !important;
  
}
.lg-checked-background .ant-checkbox-checked::after{
  border:1px solid #636e72 !important;

}
.lg-checked-background2 .ant-checkbox .ant-checkbox-inner{
  background-color: #aa1239;
  border:1px solid #aa1239 !important;

  
}

.lg-checked-background2 .ant-checkbox-checked::after{
  border:1px solid #aa1239 !important;

}



.lg-card-container .card-body{
  padding: 5px 24px ;
}

