.answers-fill-in-the-blank {
  display: inline-flex;
  flex-direction: column;
}

.answerButton-fill-in-the-blank {
  margin: 0px !important;
  margin-bottom: 20px !important;
  text-align: left;
  white-space: nowrap;
  min-width: 300px;
}

.input-fill-in-the-blank {
  color: #2dce89 !important;
  font-weight: 600;
  font-size: 18px;
  height: 32px;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px dashed #ced4da;
  text-align: center;
  box-shadow: none !important;
}

/* sm */
@media screen and (max-width: 540px) {
  .answers-fill-in-the-blank {
    display: flex;
    flex-direction: column;
  }
  .answerButton-fill-in-the-blank {
    margin: 0px !important;
    margin-bottom: 20px !important;
  }
}
