.row_presentation {
  margin-bottom: 0.5rem !important;
  border-radius: 5px;
  border: 0.25px solid #ccc;
  overflow: hidden;
}

.row_presentation_title {
  background-color: white;
  padding: 0.25rem !important;
}

.row_presentation_slide {
  border-radius: 5px;
  /* border: 0.25px solid #ccc; */
  overflow: hidden;
  /* margin-left: 1.5rem !important; */
  margin-block: 0.5rem !important;
  cursor: pointer;
}

.row_presentation_slide :hover {
  background-color: #ccc !important;
}
