.circle_container:hover {
  transform: scale(1.1);
}

.row_lesson {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
}

.row_week {
  display: flex;
  margin-bottom: 5px;
}
.row_week:hover {
  background-color: #e2f8f8;
}
.col_lesson_blank {
  min-width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.col_lesson_container {
  padding: 1rem !important;
  /* width: 350px; */
  min-width: 350px;
  flex: 1;
  /* padding-block: 1rem !important */
}

.col_lesson_content {
  color: white;
  padding: 6px;
  text-align: center;
  font-size: 16px;
  min-width: 110px;
}

.col_folder_title {
  min-width: 250px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-block: 12px;
  color: white;
  font-size: 28px;
  font-weight: 700;
}

.col_folder_lesson {
  color: black;
  padding-left: 0.5rem !important;
  padding-block: 0.5rem !important;
}
.arrow_tag {
  cursor: pointer;
  position: relative;
  padding: 5px !important;
  /* width: 350px !important; */
  color: white;
  font-size: 1.1em;
  text-align: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.arrow_part {
  padding: 5px !important;
  position: absolute !important;
  top: 0;
  right: -20px;
  height: 100%;
  width: 30px;
  background-color: #fff;
  border-radius: 0 40px 40px 0;
}

.triangleRight {
  width: 0;
  height: 0;
  /* margin: 30px auto; */
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 20px solid grey;
}
