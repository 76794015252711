.row_container {
  /* overflow-y: auto; */
}

.row_lesson {
  width: 100%; height: 100%; overflow: auto; padding: 0;
}

.row_week {
  display: flex;
  margin-bottom: 5px;
}
.row_week:hover {
  background-color: #e2f8f8;
}
.col_lesson_blank {
  min-width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.col_lesson_container {
  padding: 1rem !important;
  /* width: 350px; */
  min-width: 350px;
  flex: 1;
  /* padding-block: 1rem !important */
}

.col_lesson_content {
  color: white;
  padding: 6px;
  text-align: center;
  font-size: 20px;
}

.col_folder_title {
  min-width: 250px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-block: 12px;
  color: white;
  font-size: 28px;
  font-weight: 700;
}

.col_folder_lesson {
  color: black;
  padding-left: 0.5rem !important;
  padding-block: 0.5rem !important;
}
.arrow_tag {
  cursor: pointer;
  position: relative;
  padding: 5px !important;
  /* width: 350px !important; */
  color: white;
  font-size: 1.1em;
  text-align: center;
}

/* .arrow_tag::after {
  background-color: #1dd5ee !important;
  position: absolute !important;
  right: -8px !important;
  top: 8px !important;
  width: 27px !important;
  height: 27px !important;
  transform: rotate(45deg);
  content: '';
  border: 1px solid white !important;
  z-index: -1;
} */
.arrow_part {
  background-color: #1dd5ee;
  position: absolute !important;
  right: -23px !important;
  top: 8px !important;
  width: 46px !important;
  height: 46px !important;
  transform: rotate(45deg);
  border: 1px solid white !important;
}