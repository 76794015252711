.answers-onechoice-vertical {
  display: inline-flex;
  flex-direction: column;
}

.answers-onechoice-horizontal {
  display: inline-flex;
  flex-direction: row;
}

.answerButton-onechoice {
  margin: 0px !important;
  margin-bottom: 20px !important;
  text-align: left;
  /* white-space: nowrap; */
}

.answerButton-onechoice-vertical {
  min-width: 240px;
}

.answerButton-onechoice-horizontal {
  /* min-width: 160px; */
  margin-right: 12px !important;
}

/* sm */
@media screen and (max-width: 540px) {
  .answers-onechoice {
    display: flex;
    flex-direction: column;
  }

  .answerButton-onechoice {
    margin: 0px !important;
    margin-bottom: 20px !important;
    max-width: 100%;
  }
}
