.slide-row:hover {
  background-color: lightblue;
}

.ant-card-bordered {
  border: none;
}

.ant-card-bordered .ant-card-body {
  padding: 0;
}

.swimlane_col_lesson_container {
  min-width: 350px;
  flex: 1 1;
  border: 1px solid wheat;
}

.current-slide-button {
  color: white;
  border-radius: 0.5rem;
  font-size: 16px;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.current-slide-button:hover {
  opacity: 0.7;
  transform: scale(1.1);
}
