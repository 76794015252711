.blackTheme {
    background-color: black;
    color: white !important;
}

.blackTheme h1,
.blackTheme h2,
.blackTheme h3,
.blackTheme h4,
.blackTheme h5,
.blackTheme h6,
.blackTheme p {
    color: white !important;
}