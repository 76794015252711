.react-player__preview {
  background-size: 100% 100% !important;
}

.play-icon {
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 38px);
  cursor: pointer;
}
