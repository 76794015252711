.boxOnechoice {
  /* Mặc định chưa chọn */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: 2px dotted #d9d9d9;
  min-height: 16px;
  background-color: #d9d9d930;
}
.boxOnechoice:hover.boxOnechoiceHover {
  /* Khi Đưa chuột vào */
  border: 2px dotted #006ee6;
  background-color: #006ee630;
}
.boxOnechoiceClick {
  /* Khi chọn */
  border: 2px solid #006ee6;
  background-color: #006ee630;
}

/* Đúng sai */

.onchoiceTrue {
  border: 2px solid #2dce89 !important;
  background-color: #2dce8930;
}
.onchoiceFalse {
  border: 2px solid #f5365c;
  background-color: #f5365c30;
}
.onchoiceRetry {
  border: 2px solid #006ee6;
  background-color: #006ee630;
}
.noSubmit {
  border: 2px dotted #d9d9d9;
  background-color: #d9d9d930;
}
