.type-combine {

    .question-text-combine {
        padding: 0 20px;
        box-shadow: none;
        border: 1px solid #e9e9e9;
        border-radius: 1rem;
        height: calc(100vh - 230px);
        overflow-y: auto;
        text-align: justify;
    }

    .question-component-combine {
        padding: 5px;
        box-shadow: none;
        border: 1px solid #e9e9e9;
        border-radius: 1rem;
        height: calc(100vh - 230px);
        overflow-y: auto;
    }
}